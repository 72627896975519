export const GRAPHIC_COLORS = {
  accent100: '#CBFFCE',
  accent200: '#BFF3D2',
  base300: '#5D5980',
  cool200: '#38C1EC',
  success200: '#69C9A0',
  warning200: '#FFA858',
  tertiary300: '#7595F5',
  warm200: '#D19EF0',
  warm400: '#B600D3',
  warm500: '#935AED',
  warm600: '#634DA0',
  warm700: '#504FA7',
  cool300: '#0E7DA9',
  cool400: '#0075B7',
  cool500: '#2861B6',
  cool800: '#405EC9',
  white: '#fff',
  black: '#000',
  gradient: '#fff',
  danger100: '#DA1212'
}

export const CHART_THEME = {
  axisColor: GRAPHIC_COLORS.base300,
  defaultLight: GRAPHIC_COLORS.white,
  defaultDark: GRAPHIC_COLORS.black,
  tickColor: GRAPHIC_COLORS.white,
  success: GRAPHIC_COLORS.success200,
  warning: GRAPHIC_COLORS.warning200,
  tooltipBackground: GRAPHIC_COLORS.gradient,
  gridColor: GRAPHIC_COLORS.base300,
}
