import styles from "./ChartDashboard.module.css";
/* Renders Chart Dashboard i.e collection of charts with other functionalities */
/*
* @param children
* the children element wrapped in this component
* */
const ChartDashboard = ({ children, className }: any) => {
  return (
    <div className={`${styles.chartDashboard} ${className}`}>
      {children}
    </div>
  );
}

export default ChartDashboard;
