import { CHART_THEME } from '../assets/chartTheme';
import { ComboChartConfig } from '../model/chart.type';
import * as Generic from '../util/genericConfigMapper';

/** Function to get the mapping data for the combo chart

 * @param config
 * @param data
 * @returns mappingData - used to map the config and data to the chart in chart.js
 */
export const getChartMappingData = (config?: ComboChartConfig, data?: any) => {
  return Generic.getChartMappingData(config, data);
}

export const getChartOptionsCombo = (config?: ComboChartConfig) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: CHART_THEME.tickColor,
          display: config?.barOptions?.showTicks?.xAxis ?? true
        },
        grid: {
          display: false,
        },
        border: {
          color: CHART_THEME.axisColor
        },
        barThickness: config?.barOptions?.barThickness ?? 'flex',
      },
      y: {
        type: 'linear',
        stacked: false,
        display: true,
        grid: {
          display: true,
          color: CHART_THEME.gridColor,
        },
        border: {
          display: false
        },
        position: 'left',
        maxBarThickness: config?.barOptions?.barThickness ?? 'flex',
        // barThickness: config?.barOptions?.barThickness ?? 'flex',
        // data formatter for the y axis to be passed in the config
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return config?.yAxes?.[0]?.axisConfig?.dataFormatter?.(value) ?? value;
          },
        }
      },
      y1: {
        type: 'linear',
        stacked: false,
        display: config?.yAxes?.length === 2,
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        border: {
          display: false
        },
        position: 'right',
        // data formatter for the y axis to be passed in the config
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return config?.yAxes?.[1]?.axisConfig?.dataFormatter?.(value) ?? value;
          },
        }

      }
    },
    layout: config?.layout ?? {},
    plugins: {
      tooltip: config?.tooltipOptions ?? {},
      // legend display set to false to hide the legend in the chart and use the custom ChartLegend component
      legend: { display: false, },
      datalabels: config?.dataLabels?.display ? Generic.dataLabelMapper(config) : { display: false },
    }
  };
}
