import { AxesConfig, ChartConfig } from "../../model/chart.type";
import styles from './ChartLegend.module.scss';
// @ts-ignore
import { Typography } from "@aiops/styleguide";

type ChartLegendProps = {
  config?: ChartConfig;
  data?: any;
}

const ChartLegend = ({ config, data }: ChartLegendProps) => {

  const getLegendsToRender = () => {
    // if config legendOptions axisType is set to 'y', then return yAxes mapping else return xAxes mapping
    if (config?.legendOptions?.axisType === 'Y') {
      return config?.yAxes;
    } else {
      return config?.xAxes.filter((xAxis: AxesConfig) => {
        return xAxis.key === config?.legendOptions?.axisKey;
      })[0]?.mapping;
    }
  };

  return (
    <div className={styles.chartLegend}>
      {config?.legendOptions?.label && <Typography variant="caption1c" className="label">{config?.legendOptions?.label}:</Typography>}
      <div className={`${styles.legend} legend-container`}>
        {getLegendsToRender()?.map((legend) => {
          return (
            <div className={styles.legendItem} key={legend.key}>
              <div className="color-indicator" style={{ backgroundColor: legend.color }} />
              <Typography variant="caption1c" className="label">{config?.legendOptions?.showCount && `${data[legend.key]} `}{legend.label}</Typography>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default ChartLegend;
