// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5aEfc1QSzLiCt7e8ZSMu6w\\=\\={display:flex;justify-content:center;align-items:center;flex-direction:column}._5aEfc1QSzLiCt7e8ZSMu6w\\=\\= div[class*=no-chart-found__icon]{display:flex;justify-content:center}._5aEfc1QSzLiCt7e8ZSMu6w\\=\\= div[class*=no-chart-found__icon]>img{filter:var(--filter-primary-brightlupine)}", "",{"version":3,"sources":["webpack://./src/components/NoChartFound/NoChartFound.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CAEE,8DACE,YAAA,CACA,sBAAA,CACA,kEACE,yCAAA","sourcesContent":[".noChartFound {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n    div[class*=\"no-chart-found__icon\"] {\n      display: flex;\n      justify-content: center;\n      > img {\n        filter: var(--filter-primary-brightlupine);\n      }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noChartFound": "_5aEfc1QSzLiCt7e8ZSMu6w=="
};
export default ___CSS_LOADER_EXPORT___;
