import { CHART_THEME } from '../assets/chartTheme';
import { BarChartConfig } from '../model/chart.type';
import * as Generic from '../util/genericConfigMapper';

export const getChartMappingData = (config?: BarChartConfig, data?: any) => {
  return Generic.getChartMappingData(config, data);
}

export const getChartOptionsBar = (config?: BarChartConfig) => {
  return {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: config?.barOptions?.xAxisStacked ?? false,
        ticks: {
          color: CHART_THEME.tickColor,
          display: config?.barOptions?.showTicks?.xAxis ?? true
        },
        grid: {
          display: false,
        },
        border: {
          color: CHART_THEME.axisColor
        }
      },
      y: {
        stacked: config?.barOptions?.yAxisStacked ?? false,
        display: false,
      },
    },
    plugins: {
      tooltip: config?.tooltipOptions ?? {},
      // legend display set to false to hide the legend in the chart and use the custom ChartLegend component
      legend: { display: false, },
      datalabels: (config?.dataLabels?.display ? { ...Generic.dataLabelMapper(config) } : { display: false }),
    }
  };
}
