// @ts-ignore
import { Paper } from "@aiops/styleguide";
import styles from './ChartCard.module.scss';
import ChartCardTitle from "../ChartCardTitle/ChartCardTitle";

type ChartCardProps = {
  title?: string,
  className?: string,
  children: any,
  filled?: boolean
}

const ChartCard = ({ title, className, children, filled = false }: ChartCardProps): JSX.Element => {

  return (
    <Paper elevation={0} className={`${styles.chartCardContainer} ${className} ${filled && styles.opaque}`}  >
      {title && <ChartCardTitle {...{ title }} />}
      {children}
    </Paper>
  );
}

export default ChartCard;
