import { CHART_THEME } from "../assets/chartTheme";
import { ChartConfig } from "../model/chart.type";

export const setColorAsSelectedAxis = (config: ChartConfig, data: any, mappingData: any, yAxisDataSets: any) => {
  config?.xAxes?.[0]?.mapping?.forEach((item) => {
    mappingData.labels.push(item?.label ?? '');
    Object.keys(yAxisDataSets).forEach((key) => {
      /* means no key and y axis value can be found using keys in x axis */
      if (key === '') {
        yAxisDataSets[key].data.push(data?.[item?.key] ?? 0);
      }
      /* key is defined and is not ''. if it is 0 its plotted on the chart */
      else if (typeof data?.[item?.key]?.[key] !== 'undefined' && data?.[item?.key]?.[key].toString().length > 0) {
        yAxisDataSets[key].data.push(data?.[item?.key]?.[key]);
      }
      /* if color defined by X-Axis */
      if (config?.xAxes?.[0].isColorAxis) {
        yAxisDataSets[key].backgroundColor.push(item?.color ?? CHART_THEME.defaultLight);
        yAxisDataSets[key].borderColor.push(item?.color ?? CHART_THEME.defaultLight);
      }
      /* if data labels are enabled */
      config?.dataLabels?.display && yAxisDataSets[key].datalabels.color.push(item?.textColor ?? CHART_THEME.defaultDark);
    });
    mappingData.dataSets = Object.values(yAxisDataSets);
  });

  return mappingData;
}

export const dataLabelMapper = (config: ChartConfig) => {
  return {
    display: true,
    color: config?.dataLabels?.color ?? CHART_THEME.defaultDark,
    formatter: config?.dataLabels?.formatter ?? ((value: any, context: any) => value),
    font: config?.dataLabels?.font ?? {
      size: 12,
      lineHeight: 1.2,
      family: 'Open Sans',
    },
    anchor: config?.dataLabels?.anchor ?? 'center',
    align: config?.dataLabels?.align ?? 'center',
    offset: config?.dataLabels?.offset ?? 4,
  };
}

export const getChartMappingData = (config?: ChartConfig, data?: any) => {
  let mappingData: { labels: string[], dataSets: any[], backgroundColor: string[], borderColor: string[], datalabels: { color: string[] } }
    = { labels: [], dataSets: [], backgroundColor: [], borderColor: [], datalabels: { color: [] } };
  if (config) {
    const yAxisDataSets: Record<string, any> = {};
    config?.yAxes?.forEach((yAxis, index) => {
      const yAxisKey = yAxis.key.toString();
      yAxisDataSets[yAxisKey] = {
        label: yAxis?.label ?? '',
        data: [],
        /* if color defined by Y-Axis */
        ...(yAxis.isColorAxis ? {
          backgroundColor: yAxis?.color ?? CHART_THEME.defaultLight,
          borderColor: yAxis?.color ?? CHART_THEME.defaultLight
        } :
          /* if color defined by X-Axis for each datapoint */
          {
            backgroundColor: [],
            borderColor: []
          }),
        ...(config?.dataLabels?.display && {
          datalabels: {
            color: []
          }
        }
        ),
        // to define the type of chart for each measure / y axis data
        ...(
          yAxis?.axisConfig?.type && {
            type: yAxis?.axisConfig?.type
          }
        ),
        // // to identify the axis for the data
        ...(
          yAxis?.axisConfig?.id && {
            yAxisID: yAxis.axisConfig?.id
          }
        ),
        // // order needed to layer mulitple datasets/chart type on top of each other
        ...(
          (typeof yAxis?.axisConfig?.order !== 'undefined') && {
            order: yAxis.axisConfig?.order
          }
        )

      };
    })

    mappingData = setColorAsSelectedAxis(config, data, mappingData, yAxisDataSets);
  }
  return mappingData;
}
