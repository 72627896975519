// @ts-ignore
import { Typography } from '@aiops/styleguide';
import styles from './ChartCardTitle.module.scss';

/* Renders Chart card title */
/*
* @param title
* title to be displayed by default at the center of the card
* */
type ChartTitleProps = {
  title?: string;
  position?: 'left' | 'center' | 'right';
}

const ChartCardTitle = ({ title, position = 'center' }: ChartTitleProps): JSX.Element => {
  return (<div className={`${styles.chartTitleContainer} ${position}`}>
    <Typography variant="paragraph1-bold">{title}</Typography>
  </div>);
}

export default ChartCardTitle
