// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iD\\+KWr1TqJhI8wAAavY8SA\\=\\={display:flex;justify-content:center;margin-top:10px}.iD\\+KWr1TqJhI8wAAavY8SA\\=\\= *[class*=label]{color:var(--hibiscus)}.iD\\+KWr1TqJhI8wAAavY8SA\\=\\= .yTCuH6HfjSQkpEKfXVJMjw\\=\\={display:flex;flex-wrap:wrap;row-gap:4px}.iD\\+KWr1TqJhI8wAAavY8SA\\=\\= .yTCuH6HfjSQkpEKfXVJMjw\\=\\= .COslv5U1wW9iypiNpMyQCg\\=\\={display:flex;margin-right:10px;margin-left:16px}.iD\\+KWr1TqJhI8wAAavY8SA\\=\\= .yTCuH6HfjSQkpEKfXVJMjw\\=\\= div[class*=color-indicator]{width:16px;height:16px;margin-right:5px;border-radius:50%}", "",{"version":3,"sources":["webpack://./src/components/ChartLegend/ChartLegend.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,sBAAA,CACA,eAAA,CAEA,6CACE,qBAAA,CAGF,yDACE,YAAA,CACA,cAAA,CACA,WAAA,CAEA,qFACE,YAAA,CACA,iBAAA,CACA,gBAAA,CAGF,qFACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".chartLegend{\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n\n  *[class*=\"label\"] {\n    color: var(--hibiscus);\n  }\n\n  .legend {\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 4px;\n\n    .legendItem{\n      display: flex;\n      margin-right: 10px;\n      margin-left: 16px;\n    }\n\n    div[class*=\"color-indicator\"]{\n      width: 16px;\n      height: 16px;\n      margin-right: 5px;\n      border-radius: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartLegend": "iD+KWr1TqJhI8wAAavY8SA==",
	"legend": "yTCuH6HfjSQkpEKfXVJMjw==",
	"legendItem": "COslv5U1wW9iypiNpMyQCg=="
};
export default ___CSS_LOADER_EXPORT___;
