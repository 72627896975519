import { CHART_THEME } from "../assets/chartTheme";
import { DoughnutChartConfig } from "../model/chart.type";
import { setColorAsSelectedAxis } from "./genericConfigMapper";

export const getChartMappingData = (config?: DoughnutChartConfig, data?: any) => {
  let mappingData: { labels: string[], dataSets: any[], backgroundColor: string[], borderColor: string[], datalabels: { color: string[] } }
    = { labels: [], dataSets: [], backgroundColor: [], borderColor: [], datalabels: { color: [] } };
  if (config) {
    const yAxisDataSets: Record<string, any> = {};
    config?.yAxes?.forEach((yAxis) => {
      yAxisDataSets[yAxis.key.toString()] = {
        label: yAxis?.label ?? '',
        data: [],
        // setting empty array as values for backgroundColor and borderColor would depend on x axis value
        ...(config?.xAxes?.[0].isColorAxis && {
          backgroundColor: [],
          borderColor: []
        }),
        datalabels: {
          color: []
        }
      };
    });
    mappingData = setColorAsSelectedAxis(config, data, mappingData, yAxisDataSets);
  }
  return mappingData;
}

export const getChartOptionsDoughnut = (config?: DoughnutChartConfig) => ({
  // to take space of the parent container with limitations
  maintainAspectRatio: false,
  cutout: config?.doughnutOptions?.cutoutPercentage ?? '80%',
  plugins: {
    // tooltip plugin to show the tooltip in the chart on most charts its disabled for now by passing enabled as false
    tooltip: config?.tooltipOptions ?? {},
    // legend display set to false to hide the legend in the chart and use the custom ChartLegend component
    legend: {
      display: false,
    },
    // datalabels plugin to show the data labels in the chart. these are common datalabel properties specific dataset ones like color are set in dataSets
    datalabels: config?.dataLabels?.display ? {
      display: true,
      formatter: config?.dataLabels?.formatter ?? ((value: any) => value),
      font: config?.dataLabels?.font ?? {},
      ...(config?.dataLabels?.color ? { color: config?.dataLabels?.color ?? CHART_THEME.defaultDark } : {}),
    } : { display: false },
  }
})
