import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  PointElement, LineElement, LineController
} from 'chart.js';
import { useEffect, useState } from "react";
import { ComboChartUtil } from "../../util";
import { BarChartConfig } from "../../model/chart.type";

ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

type ComboChartProps = {
  config?: BarChartConfig;
  data?: any;
}

const ComboChart = ({ config, data }: ComboChartProps) => {
  const [chartData, setChartData] = useState<any>({});
  const [options, setOptions] = useState<any>({});
  const [chartReady, setChartReady] = useState<boolean>(false);

  useEffect(() => {
    const customChartData = ComboChartUtil.getChartMappingData(config, data);
    setChartData((prevState: any) => ({
      ...prevState,
      labels: customChartData.labels,
      datasets: customChartData.dataSets,
    }));
    setOptions((prevState: any) => ({
      ...prevState,
      ...ComboChartUtil.getChartOptionsCombo(config)
    }));
    setChartReady(true);
    return () => { };
  }, [config, data]);

  return (<div className="chart-render-container">
    {chartReady ? <Bar data={chartData} options={options} /> : ''}
  </div>);
}

export default ComboChart;
