import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DoughnutChartConfig } from "../../model/chart.type";
import { DoughnutChartUtil } from "../../util";
import { getAfterDatasetDraw } from "../../util/doughnutChartHelper";
ChartJS.register(ArcElement, LinearScale, Tooltip, Legend, ChartDataLabels);

type DoughnutChartProps = {
  config?: DoughnutChartConfig,
  data?: any;
}

const DoughnutChart = ({ config, data }: DoughnutChartProps) => {
  const [chartData, setChartData] = useState<any>({});
  const [options, setOptions] = useState<any>({});
  const [plugins, setPlugins] = useState<any>([]);
  const [chartReady, setChartReady] = useState<boolean>(false);

  useEffect(() => {
    const customChartData = DoughnutChartUtil.getChartMappingData(config, data);
    setChartData((prevState: any) => ({
      ...prevState,
      labels: customChartData.labels,
      datasets: customChartData.dataSets,
    }));

    setOptions((prevState: any) => ({
      ...prevState,
      ...DoughnutChartUtil.getChartOptionsDoughnut(config)
    }));

    setChartReady(true);

    if (config?.doughnutOptions?.innerDataLabel) {
      const label = config?.doughnutOptions?.innerDataLabel?.label || "";
      const count = data[config?.doughnutOptions?.innerDataLabel?.metricKey] || 0;
      const afterDatasetsDraw = getAfterDatasetDraw(label, count);
      setPlugins([{ id: "centerText", afterDatasetsDraw }]);
    }
    setChartReady(true);

    return () => {
      setChartReady(false);
      setPlugins([]);
      setChartData({});
      setOptions({});
    };
  }, [config, data]);

  return (
    <div>
      {chartReady ? <Doughnut data={chartData} options={options} {...{ plugins }} redraw={true} /> : ""}
    </div>
  );
}

export default DoughnutChart;
