// need to see if there is a better way to do this
import { CHART_THEME } from "../assets/chartTheme";

export const getAfterDatasetDraw = (innerText: string, doughnutTotal?: number) => {
  return (chart: any) => {
    // destructure ctx from chart
    const { ctx } = chart;
    // get the co-ordinated of the center of the chart centerOfChart = { x: number, y: number }
    const centerOfChart = chart.getDatasetMeta(0).data[0];
    const centerText = {
      metric: {
        value: doughnutTotal,
        // this could be configurable in future
        fontSize: "24px",
        fontWeight: "800",
        fontFamily: "Open Sans",
        textAlign: "center",
        textBaseline: "middle",
      },
      label: {
        value: innerText,
        // this could be configurable in future
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "Open Sans",
        textAlign: "center",
        textBaseline: "middle",
      }
    };

    // restore the context  to its default state
    ctx.restore();

    ctx.fillStyle = CHART_THEME.defaultLight;
    // draw the metric value
    ctx.font = `${centerText.metric.fontWeight} ${centerText.metric.fontSize} ${centerText.metric.fontFamily}`;
    ctx.textAlign = centerText.metric.textAlign;
    ctx.textBaseline = centerText.metric.textBaseline;
    ctx.translateX = 0;

    ctx.fillText(centerText.metric.value, centerOfChart.x, centerOfChart.y - 12); // centerOfChart.y - metricTextHeight / 2

    // draw the label
    ctx.font = `${centerText.label.fontWeight} ${centerText.label.fontSize} ${centerText.label.fontFamily}`;
    ctx.textAlign = centerText.label.textAlign;
    ctx.textBaseline = centerText.label.textBaseline;

    ctx.fillText(centerText.label.value, centerOfChart.x, centerOfChart.y + 16);

    // save the context
    ctx.save();
  };
};
