// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JKKWdi1NxQvTGg8qV5GtLg\\=\\= {\n    display: flex;\n    justify-content: space-between;\n    gap: 10px;\n    flex: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ChartDashboard/ChartDashboard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,OAAO;AACX","sourcesContent":[".chartDashboard {\n    display: flex;\n    justify-content: space-between;\n    gap: 10px;\n    flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartDashboard": "JKKWdi1NxQvTGg8qV5GtLg=="
};
export default ___CSS_LOADER_EXPORT___;
