// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S6PzJeqRnpw8DWf4eIF4fA\\=\\={border:1px solid var(--sad-lilac);padding:16px;display:flex;flex:1;flex-direction:column;align-items:center;background-color:var(--gradient-card-bg)}.mgRTHVhgUWnCTsSSLgMlkw\\=\\={background-color:var(--table-row-bg)}", "",{"version":3,"sources":["webpack://./src/components/ChartCard/ChartCard.module.scss"],"names":[],"mappings":"AAAA,4BACI,iCAAA,CACA,YAAA,CACA,YAAA,CACA,MAAA,CACA,qBAAA,CACA,kBAAA,CACA,wCAAA,CAGJ,4BACI,oCAAA","sourcesContent":[".chartCardContainer {\n    border: 1px solid var(--sad-lilac);\n    padding: 16px;\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    align-items: center;\n    background-color: var(--gradient-card-bg);\n}\n\n.opaque {\n    background-color: var(--table-row-bg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartCardContainer": "S6PzJeqRnpw8DWf4eIF4fA==",
	"opaque": "mgRTHVhgUWnCTsSSLgMlkw=="
};
export default ___CSS_LOADER_EXPORT___;
