import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { useEffect, useState } from "react";
import { BarChartConfig } from "../../model/chart.type";
import { BarChartUtil } from "../../util";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type BarChartProps = {
  config?: BarChartConfig;
  data?: any;
}

const BarChart = ({ config, data }: BarChartProps) => {
  const [chartData, setChartData] = useState<any>({});
  const [options, setOptions] = useState<any>({});
  const [chartReady, setChartReady] = useState<boolean>(false);

  useEffect(() => {
    const customChartData = BarChartUtil.getChartMappingData(config, data);
    setChartData((prevState: any) => ({
      ...prevState,
      labels: customChartData.labels,
      datasets: customChartData.dataSets,
    }));
    setOptions((prevState: any) => ({
      ...prevState,
      ...BarChartUtil.getChartOptionsBar(config)
    }));
    setChartReady(true);
    return () => { };
  }, [config, data]);

  return (<div>
    {chartReady ? <Bar data={chartData} options={options} /> : ''}
  </div>);
}

export default BarChart;
