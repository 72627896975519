// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3nZu56KwbHZk-AtkqTAEjQ\\=\\={display:flex;height:24px}._3nZu56KwbHZk-AtkqTAEjQ\\=\\=.G8rWjjftPHL5\\+j2XleoLYA\\=\\={justify-content:center}._3nZu56KwbHZk-AtkqTAEjQ\\=\\=.PqZFbA44VLDbGWNN5iTUmg\\=\\={justify-content:flex-end}._3nZu56KwbHZk-AtkqTAEjQ\\=\\=.gRESICec\\+m64x0oYEJnH0g\\=\\={justify-content:flex-start}", "",{"version":3,"sources":["webpack://./src/components/ChartCardTitle/ChartCardTitle.module.scss"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,WAAA,CAEA,yDACI,sBAAA,CAGJ,wDACI,wBAAA,CAGJ,yDACI,0BAAA","sourcesContent":[".chartTitleContainer {\n    display: flex;\n    height: 24px;\n\n    &.center {\n        justify-content: center;\n    }\n\n    &.right {\n        justify-content: flex-end;\n    }\n\n    &.left {\n        justify-content: flex-start;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartTitleContainer": "_3nZu56KwbHZk-AtkqTAEjQ==",
	"center": "G8rWjjftPHL5+j2XleoLYA==",
	"right": "PqZFbA44VLDbGWNN5iTUmg==",
	"left": "gRESICec+m64x0oYEJnH0g=="
};
export default ___CSS_LOADER_EXPORT___;
