//@ts-ignore
import { CircularProgress, Typography } from '@aiops/styleguide';
import styles from './Chart.module.scss';
import { DoughnutChart, BarChart, ComboChart } from '../../charts';
import { ChartType } from '../../constants';
import { ChartConfig } from '../../aiops-charting';
import NoChartFound from '../NoChartFound/NoChartFound';
import ChartLegend from '../ChartLegend/ChartLegend';

type ChartProps = {
  title?: string;
  subtitle?: string;
  children?: any;
  isLoading?: boolean;
  isError?: boolean;
  type: ChartType;
  config?: ChartConfig;
  data?: any;
}

const Chart = ({ type, title, subtitle, isLoading, isError, config, data }: ChartProps) => {

  const renderChartTitleLegend = () => {
    /* if legend to be rendered top or left of chart it needs to render before chart in the DOM */
    const legendRenderBeforeChart = config?.legendOptions?.position === 'top' || config?.legendOptions?.position === 'left';
    return (
      <>
        {title && <Typography variant="paragraph1-bold" className="chart-title">{title}</Typography>}
        {subtitle && <Typography variant="caption1c" className="chart-subtitle">{subtitle}</Typography>}
        <div className={`${styles.chartContainer} ${config?.legendOptions?.position} chart-container`}>
          {config?.legendOptions?.display && legendRenderBeforeChart && <ChartLegend {...{ config, data }} />}
          {getChartToRender()}
          {config?.legendOptions?.display && !legendRenderBeforeChart && <ChartLegend {...{ config, data }} />}
        </div>
      </>
    );
  }

  const getChartToRender = () => {
    switch (type) {
      case ChartType.Doughnut:
        return <DoughnutChart {...{ config, data }} />
      case ChartType.StackedBar:
        return <BarChart {...{ config, data }} />
      case ChartType.Bar:
        return <BarChart {...{ config, data }} />
      case ChartType.Combo:
        return <ComboChart {...{ config, data }} />
      default:
        return <div>Chart type not supported</div>
    }
  }

  const isNoData = (data: any) => {
    return (typeof (data) === "undefined" || data === null || Object.keys(data).length === 0);
  }

  return (
    <div className={`${styles.chartStyles} chart`}>
      {isLoading && <div className={'loader-container'}><CircularProgress /></div>}
      {!isLoading &&
        <>
          {(isNoData(data) || isError) ?
            <NoChartFound /> :
            <>
              {renderChartTitleLegend()}
            </>
          }
        </>}
    </div>);
}

export default Chart;
