import { Typography } from "@aiops/styleguide";
import IconNoChartFound from '../../assets/icon-solar-chart-broken.svg';
import styles from './NoChartFound.module.scss';

const NoChartFound = () => {
  return (
    <div className={styles.noChartFound}>
      <div className="no-chart-found__icon">
        <img src={IconNoChartFound} alt="No chart found" />
      </div>
      <div className="no-chart-found__text">
        <Typography variant={"heading5-light"}>Sorry, no analysis found.</Typography>
        <Typography variant={"paragraph1"}>Please refresh the screen and try again.</Typography>
      </div>
    </div>
  );
}

export default NoChartFound;
